<template>
  <div>
    <van-form @submit="toPay">
      <div v-if="payTypeId == 20 && (!user_email || user_email == '')">
        <van-field v-model="first_name" maxlength="10" name="first_name" label="first name" placeholder="please enter first name"
          :rules="[{ required: true, message: 'please enter your first name' }]" />
        <van-field v-model="last_name" maxlength="10" name="last_name" label="last name" placeholder="please enter last name"
          :rules="[{ required: true, message: 'please enter your last name' }]" />
        <van-field v-model="email" name="email" maxlength="30" label="email" placeholder="please enter your email"
          :rules="[{ required: true, message: 'please enter your email' }, { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Please enter the correct email address' }]" />
      </div>
      <!--支付方式-->
      <van-cell title="Mode of Payment" style="text-align: center"></van-cell>

      <div class="payWrap">
        <van-radio-group v-model="payTypeId">
          <div class="payitem" v-for="item in payTypeList" :key="item.type" @click="payTypeId = item.type">
            <img class="payitem_icon" :src="item.src" alt="">
            <div class="payDesc">Foreign currency</div>
            <van-radio :name="item.type"></van-radio>
          </div>
        </van-radio-group>
      </div>

      <div style="margin: 16px">
        <van-button style="width: 45%; position: relative; left: 1rem;top:-0.5rem" type="primary">Recharge Now</van-button>
      </div>
    </van-form>
  </div>
</template>
  
  <script>
import {
  payMix,
  payWxRedirect,
  payLianLian,
  payMycard
} from "@/api/androdH5Pay";
import {
  stripePayment,
  AirwallexPayment,
  getUsdMoney
} from "@/api/stripePay";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { redirectToCheckout, loadAirwallex } from 'airwallex-payment-elements';
import { isSysName } from "@/utils";
loadAirwallex({
  env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
});
const PAY_Airwallex = 20
const PAY_TYPE_WX = 2
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_PAYPAL = 3
const PAY_LIAN_LIAN = 13
const PAY_PAL_CARD = 14
const PAY_MYCARD_CARD = 15
const PAY_STRIPE = 10
const PAY_Google = 21

export default {
  computed: {
    filteredPayTypeList() {
      return this.payTypeList.filter(item => item.type != 22 || isSysName() == 'ios');
    },
  },
  data() {
    return {
      payTypeList: [
        // {
        //   type: '21',
        //   desc:'外币',
        //   src: require('@/assets/credit/pay_icon/google_us.png')
        // },
        // {
        //   type: '22',
        //   desc:'外币',
        //   src: require('@/assets/credit/pay_icon/apple_us.png')
        // },
        {
          type: '3',
          desc:'外币',
          src: require('@/assets/credit/pay_icon/paypal.png')
        },
        {
          type: '14',
          desc:'外币',
          src: require('@/assets/credit/pay_icon/pCardUs.png')
        },
        {
          type: '20',
          desc:'外币',
          src: require('@/assets/credit/pay_icon/pCardUs3.png')
        },
        {
          type: '15',
          desc:'外币',
          src: require('@/assets/common/mycard.png')
        },
      ],
      first_name:'',
      last_name:'',
      email:'',
      user_id: 0,
      user_email: '',
      order_num: "",
      payTypeId: "15",
      count: 1,
      mycardShow: true
    };
  },
  mounted: function () {

  },
  created() {
    this.order_num = Base64.decode(this.$route.query.order_num);
    this.user_id = this.$route.query.user_id;
    if (this.$i18n.locale == 'zh') {
      this.$router.push({
        path: "/androd_h5_pay",
        query: {
          order_num: this.$route.query.order_num,
          user_id: this.$route.query.user_id,
        },
      })
    }

    this.user_email = localStorage.androd_user_email || '';
  },
  methods: {
    // 发起支付
    toPay() {
      Toast.loading({
        message: "Loading",
        forbidClick: true,
        duration: 0,
      });
      if (parseInt(this.payTypeId) === PAY_TYPE_WX) {
        this.payWx(this.order_num, this.user_id)
      } else if (parseInt(this.payTypeId) === PAY_TYPE_ALIPAY) {
        this.payAlipay(this.order_num)
      } else if (parseInt(this.payTypeId) === PAY_MYCARD_CARD) {
        this.payByMyCard(this.order_num)
      } else if (parseInt(this.payTypeId) === PAY_TYPE_PAYPAL) {
        this.payPaypal(this.order_num)
      }else if (parseInt(this.payTypeId)  === PAY_PAL_CARD) {
        this.payPaypalCard(this.order_num)
      } else if (parseInt(this.payTypeId) === PAY_LIAN_LIAN) {
        this.payByLianLian(this.order_num)
      }else if (parseInt(this.payTypeId) === PAY_STRIPE) {
        this.payByStripe(this.order_num)
      }else if (parseInt(this.payTypeId) === PAY_Airwallex) {
        this.payByAirwallex(this.order_num)
        localStorage.androd_user_email = this.user_email == '' ? this.email : this.user_email;
      }else if (parseInt(this.payTypeId) === PAY_Google) {
        this.googlePay(this.order_num)
      }
    },
    //空中云汇支付
    payByAirwallex(orderNum) {
      let email = this.user_email == '' ? this.email : this.user_email;
      AirwallexPayment({
        order_num: orderNum,
        first_name: this.first_name,
        last_name: this.last_name,
        email: email,
      }).then((response) => {
        console.log(response)

        const intent_id = response.intent_id;
        const client_secret = response.client_secret;
        const currency = response.currency;
        redirectToCheckout({
          env: 'prod',
          mode: 'payment',
          currency,
          intent_id, // Required, must provide intent details
          client_secret, // Required
          theme: {
            // Must provide theme to display the checkout page properly
            fonts: [
              // Customizes the font for the payment elements
              {
                src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
                family: 'AxLLCircular',
                weight: 400,
              },
            ],
          },
          successUrl: 'https://wx.playhy.com/#/success', // Must be HTTPS sites
          failUrl: 'https://wx.playhy.com/#/success', // Must be HTTPS sites
        });
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // PayPal谷歌支付
    googlePay(orderNum) {
      getUsdMoney({
        order_num: orderNum
      }).then((res) => {
        Toast.clear(true)
        let productName = encodeURIComponent(res.props_name)
        let url = `https://wx.playhy.com/pay/googlePay.html?number=${orderNum}&user_id=${this.user_id}&moneyUsd=${res.money_usd}&productName=${productName}&pay_type=${parseInt(this.payTypeId)}&return_url=androdH5Pay`
        window.location.href = url
      }).catch(() => {
        this.$toast.fail('请重试')
        Toast.clear(true)
      });

    },
    // PayPal银行卡支付页面请求
    payPaypalCard(orderNum) {
      this.$router.push({
        path: "/paypalcard",
        query: {
          number: orderNum,
          user_id: this.user_id,
          pay_type: parseInt(this.payTypeId),
          url: 'androdH5Pay'
        }
      });
      Toast.clear(true)
    },
    //stripe支付
    payByStripe(orderNum) {
      stripePayment({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: '',
        redirect_url: 'https://wx.playhy.com/#/success',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    //  连连支付
    payByLianLian(orderNum) {
      payLianLian({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: '',
        redirect_url: 'https://wx.playhy.com/#/success',
        lang:'en',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('Failure to initiate payment')
      })
    },
    // 微信
    payWx(orderNum, userId) {
      // console.log('orderNum1:' + orderNum);
      // console.log('userId2:' + userId);
      let _this = this;
      payWxRedirect(orderNum, userId, this.count).then((response) => {
        Toast.clear(true)
        this.count = 1;
        this.$router.push({
          path: "/androdH5Mid",
          query: {
            key: response.url
          },
        })
      }).catch((e) => {
        console.log(e)
        Toast.clear(true)
        this.count = e.data.count;
        if (e.code == 504 && e.data.count < 10) {
          _this.payWx(_this.order_num, _this.user_id)
        } else {
          this.count = 1;
          this.$toast.fail('Failure to initiate payment')
          // Toast.fail(e.message)
        }
      })
    },
    // PayPal
    payPaypal(orderNum) {
      payMix({
        number: orderNum,
        user_id: this.user_id,
        pay_type: parseInt(this.payTypeId),
        lang:'en',
        return_url: "https://api.hzarcticwolf.com/third/v1/offaccount/execpaypal?url_type=ios_android"
      }).then((res) => {
        //  正式调试
        console.log(res)
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "Loading",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/darkxiyou/mid",
        query: {
          key: key,
        },
      })
    },
    // mycard
    payByMyCard(orderNum) {
      payMycard({
        order_num: orderNum,
        user_id: this.user_id,
        type: 'official',
        lang:'en',
        return_url: "androd_h5_pay_" + Base64.encode("order_num=" + this.$route.query.order_num + "&user_id=" + this.$route.query.user_id),
      }).then((res) => {
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "Loading",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
  },
};
  </script>
  
  <style>
.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}

.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}

.pay_paypal_desc {
  float: left;
  display: block;
  line-height: 20px;
  margin-left: 5px;
  font-size: 13px;
  /*width: 262px;*/
}

.pay_paypal_tip {
  color: red;
}

.title_coupon {
  margin-right: 5px;
}

/* .van-coupon-list__list {
    height: 100% !important;
  } */
</style>
  